export enum fusionDataChannel {
        none = 0,
        channel1 = 1,
        channel2 = 2,
        channel3 = 3,
        channel4 = 4,
        channel5 = 5,
        channel6 = 6,
}

export namespace fusionDataChannel {
        export function name(state: fusionDataChannel): string {
                switch (state) {
                        case fusionDataChannel.none:
                                return "No channel";
                        case fusionDataChannel.channel1:
                                return "Channel 1";
                        case fusionDataChannel.channel2:
                                return "Channel 2";
                        case fusionDataChannel.channel3:
                                return "Channel 3";
                        case fusionDataChannel.channel4:
                                return "Channel 4";
                        case fusionDataChannel.channel5:
                                return "Channel 5";
                        case fusionDataChannel.channel6:
                                return "Channel 6";
                }
        }
        export function compactName(state: fusionDataChannel): string {
                switch (state) {
                        case fusionDataChannel.none:
                                return "nochannel";
                        case fusionDataChannel.channel1:
                                return "channel1";
                        case fusionDataChannel.channel2:
                                return "channel2";
                        case fusionDataChannel.channel3:
                                return "channel3";
                        case fusionDataChannel.channel4:
                                return "channel4";
                        case fusionDataChannel.channel5:
                                return "channel5";
                        case fusionDataChannel.channel6:
                                return "channel6";
                }
        }
        export function fromParamString(state: string): fusionDataChannel {
                switch (state) {
                        case "none":
                                return fusionDataChannel.none;
                        case "1":
                                return fusionDataChannel.channel1;
                        case "2":
                                return fusionDataChannel.channel2;
                        case "3":
                                return fusionDataChannel.channel3;
                        case "4":
                                return fusionDataChannel.channel4;
                        case "5":
                                return fusionDataChannel.channel5;
                        case "6":
                                return fusionDataChannel.channel6;
                        default:
                                return fusionDataChannel.none;
                }
        }
        export function toParamString(state: fusionDataChannel): string {
                switch (state) {
                        case fusionDataChannel.none:
                                return "none";
                        case fusionDataChannel.channel1:
                                return "1";
                        case fusionDataChannel.channel2:
                                return "2";
                        case fusionDataChannel.channel3:
                                return "3";
                        case fusionDataChannel.channel4:
                                return "4";
                        case fusionDataChannel.channel5:
                                return "5";
                        case fusionDataChannel.channel6:
                                return "6";
                }
        }
}
