import { useState, useEffect, Fragment } from "react";
import { motion, useAnimationControls } from "framer-motion";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import Loader, { SpinnerType } from "../../components/Spinner/Loader";
import './PrizeoutStanding.scss';
import React from 'react';
import { PrizeoutStandingViewModel } from "./PrizeoutStanding.ViewModel";
import { Subscription } from "rxjs";
import PrizeoutButton, { PrizeoutButtonType } from "../PrizeoutButton/PrizeoutButton";
import { FaChevronRight, FaChevronUp, FaChevronDown } from "react-icons/fa";
import { FaLongArrowAltRight, FaSync } from "react-icons/fa";
import { competitorDivision } from "../../Models/Common/CompetitorDivision";

interface PrizeoutStandingProps {
    viewModel: PrizeoutStandingViewModel;
    editPrizeoutComment: (prizeoutUid: string, comment: string | null) => void;
    editPrizePointsAwarded: (prizeoutUid: string, prizePointsAwarded: number | null) => void;
}

const Standing: React.FC<PrizeoutStandingProps> = ({ viewModel, editPrizeoutComment, editPrizePointsAwarded }) => {
    const animationControls = useAnimationControls();
    const selectedAnimation = useAnimationControls();

    const [comment, setComment] = React.useState(viewModel.localComment ?? "");
    const [isShowingHistory, setIsShowingHistory] = React.useState(false);

    useEffect(() => {
        animationControls.start("onscreen");
        var subs: Subscription[] = [];
        return () => subs.forEach(s => s.unsubscribe());
    }, [animationControls]);

    useEffect(() => {
        if (isShowingHistory) {
            selectedAnimation.start("onscreen");
        } else {
            selectedAnimation.start("offscreen");
        }
    }, [isShowingHistory]);

    useEffect(() => {
        setComment(viewModel.comment ?? "");
    }, [viewModel]);

    if (viewModel === null) {
        return <Loader showMessage={true} spinnerType={SpinnerType.FullPage} />;
    }

    const animationVariants = {
        offscreen: { opacity: 0, y: -10 },
        onscreen: { opacity: 1, y: 0 }
    };

    const selectedAnimationVariantsLeft = {
        offscreen: { opacity: 0, x: 50 },
        onscreen: { opacity: 1, x: 0, transition: { duration: 0.2 } }
    };

    const selectedAnimationVariantsRight = {
        offscreen: { opacity: 0, x: -50 },
        onscreen: { opacity: 1, x: 0, transition: { duration: 0.2 } }
    };

    const commentValueChange = (value: string) => {
        setComment(value);
    }

    const isCommentUpdated = () => {
        return (viewModel.comment ?? "") !== (comment ?? "");
    }

    const togglePrizePoints = () => {
        // No local, no server; set to due
        if (viewModel.localPrizePointsAwarded === null && (viewModel.prizePointsAwarded === null || viewModel.prizePointsAwarded === 0)) {
            console.log("No local, no server; set to due");
            editPrizePointsAwarded(viewModel.prizeoutUid, viewModel.prizePointsDue);
        // No local, server value; set to zero
        } else if (viewModel.localPrizePointsAwarded === null && viewModel.prizePointsAwarded !== null) {
            console.log("No local, server value; set to zero");
            editPrizePointsAwarded(viewModel.prizeoutUid, 0);
        // Local is zero, server value; set to due (next click sets to zero)
        } else if (viewModel.localPrizePointsAwarded == 0 && viewModel.prizePointsAwarded !== null) {
            console.log("Local is zero, server value; set to due (next click sets to zero)");
            editPrizePointsAwarded(viewModel.prizeoutUid, viewModel.prizePointsDue);
        // Local value, no server; reset local
        } else if (viewModel.localPrizePointsAwarded !== null && viewModel.prizePointsAwarded === null) {
            console.log("Local value, no server; reset local");
            editPrizePointsAwarded(viewModel.prizeoutUid, null);
        // Local value, server value; set to zero (next click sets to due)
        } else if (viewModel.localPrizePointsAwarded !== null && viewModel.prizePointsAwarded !== null) {
            console.log("Local value, server value; set to zero (next click sets to due)");
            editPrizePointsAwarded(viewModel.prizeoutUid, 0);
        }
    }

    return (
        <Fragment>
        <motion.div
        className="prizeoutStanding-list-item"
            initial="offscreen"
            animate={animationControls}
            variants={animationVariants}
            transition={{ duration: 0.2 }}
            >
            <div
                className={isShowingHistory ? "prizeoutStanding-card dark-glass-card" : viewModel.isDropped() ? "prizeoutStanding-card dim-glass-card" : "prizeoutStanding-card glass-card"}>
                <div className="prizeoutStanding-record-container">
                    <div className="prizeoutStanding-position-box">
                        {!viewModel.isFinal && <label className="glass-caption italic">Not final</label>}
                        <label className="glass-h2">{viewModel.formattedPosition()}</label>
                    </div>
                    <label className="glass-h2">{viewModel.wins} / {viewModel.losses} / {viewModel.ties}</label>
                </div>
                <div className="prizeoutStanding-player-details">

                    <div className="data-pair">
                        <label className="glass-caption">{competitorDivision.name(viewModel.division)} {viewModel.isDropped() && <i>", Drop R" + viewModel.dropRound</i>}</label>
                        <label className="glass-h2">{viewModel.fullName()}</label>
                    </div>
                    <label className="glass-caption italic">{viewModel.eventId}</label>
                </div>
                { viewModel.prizePointsDue !== null
                    ? <PrizeoutButton
                        prizeKey={"Prize Points"}
                        prizeValue={`${viewModel.prizePointsAwarded ?? 0} / ${viewModel.prizePointsDue}`}
                        hoverText={null}
                        isSyncingChange={viewModel.localPrizePointsAwarded != viewModel.prizePointsAwarded && viewModel.localPrizePointsAwarded !== null}
                        isToggled={viewModel.localPrizePointsAwarded == null ? viewModel.prizePointsAwarded == viewModel.prizePointsDue : viewModel.localPrizePointsAwarded == viewModel.prizePointsDue}
                        onToggle={() => togglePrizePoints()}
                        type={PrizeoutButtonType.Default}/>
                    : <label className="glass-h2">No Prize Points Due</label>
                }
                <div className="prizeoutStanding-player-details">
                    <div className="data-pair">
                        <label className="glass-caption">Comment:</label>
                        <label className="prizeout-comment">{viewModel.comment}</label>
                    </div>
                    <div className="prizeout-comment-edit-container">
                        <input
                            type="text"
                            className="glass-input"
                            placeholder={viewModel.comment ? "Change comment..." : "Add a comment..."}
                            value={comment}
                            onChange={(e) => commentValueChange(e.target.value)}
                        />
                        {viewModel.localComment !== viewModel.comment && viewModel.localComment !== null && <div className='prizeoutButton-corner-element'><FaSync className='sync-badge-icon'/></div>}
                        {isCommentUpdated() && (
                            <button
                                className="comment-save-button"
                                onClick={() => editPrizeoutComment(viewModel.prizeoutUid, comment)}>Save</button>
                        )}
                    </div>
                </div>
                <div className="prizeoutStanding-history-button-container" onClick={() => setIsShowingHistory(!isShowingHistory)}>
                    {!isShowingHistory ? <FaChevronDown className="event-data-action-icon" /> : <FaChevronUp className="event-data-action-icon" />}
                </div>
            </div>
        </motion.div>
        {isShowingHistory && 
            <div className="prizeoutStanding-history-container">
                <label className="glass-h2">Edit history</label>
                <div className="prizeoutStanding-history-list">
                    {viewModel.editHistory && viewModel.editHistory.slice().reverse().map((historyItem, historyIndex) => (
                    <div key={historyIndex} className="prizeoutStanding-history-item">
                        <label className="prizeoutStanding-history-data-label">{historyItem.timestamp}</label>
                        <label className="prizeoutStanding-history-data-label bold">{historyItem.adminName && historyItem.adminName.trim() !== "" ? historyItem.adminName : "no name"}</label>
                        <div className="prizeoutStanding-history-change-list">
                            {historyItem.changes.map((changeItem, changeIndex) => (
                                <div key={changeIndex} className="prizeoutStanding-history-change">
                                    <label className="glass-caption">{changeItem.key}</label>
                                    <label className="glass-caption">{changeItem.beforeValue}</label>
                                    <FaLongArrowAltRight className="prizeoutStanding-history-change-arrow"/>
                                    <label className="glass-caption">{changeItem.afterValue}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                </div>  
            </div>
        }
        </Fragment>
    )
}

export default Standing;