import { time } from "console";
import { eventDetails } from "../Models/Common/EventDetails";
import { eventSeason } from "../Models/Common/EventSeason";
import { eventType } from "../Models/Common/EventType";
import { gameType } from "../Models/Common/GameType";

export class CommonFormatsService {
    public static formatOptionalDate = (dateString: string | undefined) => {
        if (!dateString) return "";
        const dateOptions: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC",
        };
        return new Date(dateString).toLocaleString("en-GB", dateOptions) + " UTC";
    }

    public static formatOptionalDateCompact = (dateString: string | undefined) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        const now = new Date();
        const dateOptions: Intl.DateTimeFormatOptions = {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "UTC",
        };
        if (date.toDateString() !== now.toDateString()) {
            dateOptions.year = "2-digit";
            dateOptions.month = "2-digit";
            dateOptions.day = "2-digit";
        }
        return date.toLocaleString("en-GB", dateOptions) + " UTC";
    }

    public static formatClientFallbackDate = (dateString: string | undefined) => {
        const dateOptions: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC",
        };

        if (!dateString) {
            return new Date().toLocaleString("en-GB", dateOptions) + " UTC (client time)";
        }

        return new Date(dateString).toLocaleString("en-GB", dateOptions) + " UTC";
    }

    public static eventName = (details: eventDetails) => {
        return `${details.location} ${eventType.name(details.type)} ${eventSeason.name(details.season)}`;
    }
}
