import { BehaviorSubject, debounceTime } from "rxjs";
import { ApiConnectService } from "../../Services/ApiConnectService";
import { TournamentDataService } from "../../Services/TournamentDataService";
import { NavbarData, NavbarProperties, SyncToolConfigData } from "../../components/navbar/Navbar.ViewModel";
import { PrizeoutStandingViewModel } from "../PrizeoutStanding/PrizeoutStanding.ViewModel";
import { PrizeoutEventData } from "../PrizeoutEventSelector/PrizeoutEventSelector.ViewModel";
import { NavbarSyncToolIcon } from "../../components/navbar/NavbarSyncTool";
import { CommonFormatsService as formats } from "../../Services/CommonFormatsService";
import { Subscription } from "rxjs";
import { PrizeoutRecordsChangesTransfer } from "../../Models/Inbound/PrizeoutRecordsChangesTransfer";

export class PrizeoutDashboardViewModel {
    navbar: NavbarProperties
    selectedEvents = new BehaviorSubject<PrizeoutEventData[]>([]);
    combinedStandings = new BehaviorSubject<PrizeoutStandingViewModel[]>([]);
    currentUsername = new BehaviorSubject<string>('');
    isLoadingStandings: boolean;
    isReadOnly: boolean;
    refreshStandingsTrigger = new BehaviorSubject<void>(void 0);
    pushPrizeoutEditsTrigger = new BehaviorSubject<void>(void 0);

    standingsSubscription: Subscription | null;

    acs = new ApiConnectService();
    tournamentService = new TournamentDataService(this.acs);

    constructor(
        navbarProperties: NavbarProperties,
        isReadOnly: boolean = false) {
        this.navbar = navbarProperties;
        this.isLoadingStandings = true;
        this.isReadOnly = isReadOnly;

        const urlParams = new URLSearchParams(window.location.search);
        const userParam = urlParams.get('user');
        if (userParam) {
            this.updateUsername(userParam);
        }
        this.navbar.data.next(
            new NavbarData(
                "Prize Out Tools")
        );
        this.navbar.syncToolConfig.isSyncFeatureEnabled.next(true);
        this.navbar.syncToolConfig.data.next(new SyncToolConfigData(
            false,
            null,
            NavbarSyncToolIcon.Sync,
            "Prize Out",
            "Standings Data"
        ));
        // this.selectedEvents.subscribe(selectedEvents => {
        //     if (selectedEvents.length > 0) {
        //         this.refreshData();    
        //     } else {
        //         this.combinedStandings.next([]);
        //     }
        // })

        // The wrapper is for an external manual trigger, disabled as we currently rely on changes immediately sending the data
        // this.pushPrizeoutEditsTrigger.subscribe(() => {
            this.combinedStandings.pipe(
                debounceTime(3000)
            ).subscribe(standings => {
                var changes = standings.map(standing => {
                    var converted = standing.toPrizeoutRecordsChangeIfEdited(this.currentUsername.value ?? "No user")
                    if (converted !== null) {
                        console.log("Preparing to push change: " + converted.prizeoutUid + " - " + standing.firstName + " " + standing.lastName + " (" + converted.comment + ", " + converted.prizePointsAwarded + ")");
                    }
                    return converted;
                }).filter(change => change !== null);

                if (changes.length > 0) {
                    console.log("Pushing prizeout changes: " + changes.length);
                    var transfer = new PrizeoutRecordsChangesTransfer();
                    transfer.updatedRecords = changes;
                    this.tournamentService.PutPrizeoutRecordsChanges(transfer).subscribe(result => {
                        console.log("Pushed prizeout changes.");
                        this.refreshData(); // Manually get the latest data after pushing changes
                    });
                }
            });
        // })

        this.selectedEvents.subscribe(selectedEvents => {
            var errorDetected = false;
            if (this.standingsSubscription) {
                this.standingsSubscription.unsubscribe();
            }
            if (selectedEvents.length > 0) {
                this.standingsSubscription = this.tournamentService.GetPrizeoutStandingsRecordsContinuous(
                selectedEvents.map(event => event.eventId),
                10000,
                this.navbar.syncToolConfig.isSyncActive,
                this.refreshStandingsTrigger
            ).subscribe(results => {
                if (!results || results.length === 0) {
                console.error("Prizing standings results are null.");
                return null;
                }
                var goodResults = results.filter(result => result?.result?.eventId !== null) // Remove bad responses, will need to collect and handle them
                goodResults.sort((a, b) => (a.result?.eventId ?? "").localeCompare(b.result?.eventId ?? "")) // Sort by eventId
                const convertedStandings = goodResults.flatMap(apiStandings => {
                try {
                    if (!apiStandings?.isSuccess || !apiStandings?.result) {
                        errorDetected = true;
                        console.error("Error detected in standings results for event " + apiStandings?.result?.eventId);
                        return [];
                    }
                    return apiStandings?.result ? apiStandings.result.divisions?.flatMap(division => {
                    return division.standings.map(standing => {
                        try {
                            return {
                                standing,
                                apiResult: apiStandings.result!,
                                division
                            };
                        } catch (error) {
                            console.log("Error converting standing: " + error);
                            return null;
                        }
                    })}) : null
                } catch (error) {
                    console.log("Error converting division " + error);
                    return null;
                }}).filter(standing => standing !== null);
                console.log("Converted standings: " + convertedStandings.length);

                var badResults = results.filter(result => !result.isSuccess);
                if (errorDetected) {
                    console.error("Error detected in standings results.");
                    errorDetected = false;
                    this.navbar.syncToolConfig.data.next(new SyncToolConfigData(
                        false,
                        badResults[0], // What do I do with this?
                        NavbarSyncToolIcon.Sync,
                        "Prize Out",
                        "Updated: " + formats.formatOptionalDateCompact(results[0]?.result?.divisions[0]?.lastUpdated),
                        "Checked: " + formats.formatOptionalDateCompact(new Date().toISOString()),
                        ));
                    return;
                }
                const existingStandings = this.combinedStandings.getValue();
                const updatedStandingsMap = new Map<string, PrizeoutStandingViewModel>();

                // Add existing standings to the map
                existingStandings.forEach(standing => {
                    updatedStandingsMap.set(standing.prizeoutUid, standing);
                });

                // Merge new standings
                convertedStandings.forEach(({ standing, apiResult, division }) => {
                    const existingStanding = updatedStandingsMap.get(standing.prizeoutUid);
                    if (existingStanding) {
                        existingStanding.updateServerData(standing, apiResult, division);
                    } else {
                        updatedStandingsMap.set(standing.prizeoutUid, new PrizeoutStandingViewModel(standing, apiResult, division));
                    }
                });

                // Remove standings that are not in the new list
                const newPrizeoutUids = new Set(convertedStandings.map(({ standing }) => standing.prizeoutUid));
                existingStandings.forEach(standing => {
                    if (!newPrizeoutUids.has(standing.prizeoutUid)) {
                        updatedStandingsMap.delete(standing.prizeoutUid);
                    }
                });

                // Convert map back to array
                const mergedStandings = Array.from(updatedStandingsMap.values());
                this.combinedStandings.next(mergedStandings);
                this.isLoadingStandings = false;

                this.navbar.syncToolConfig.data.next(new SyncToolConfigData(
                false,
                results[0], // What do I do with this?
                NavbarSyncToolIcon.Sync,
                "Pairings",
                "Updated: " + formats.formatOptionalDateCompact(results[0]?.result?.divisions[0]?.lastUpdated),
                "Checked: " + formats.formatOptionalDateCompact(new Date().toISOString()),
                ));
            });
            }
            // After updating local data, push known edits
            // this.pushPrizeoutEdits(); // In wrong part of the call and currently not needed as pushed immediately
        });
        
        this.refreshData();      
    }

    refreshData = () => {
        this.refreshStandingsTrigger.next();
    }

    pushPrizeoutEdits = () => {
        this.pushPrizeoutEditsTrigger.next();
    }

    public updateSelectedEvents = (selectedEvents: PrizeoutEventData[]) => {
        console.log("Finished selecting events");
        this.selectedEvents.next(selectedEvents);
    }

    public updateUsername = (username: string) => {
        console.log("Updating username to " + username);
        this.currentUsername.next(username);

        // Update the user query param in the URL
        const url = new URL(window.location.href);
        url.searchParams.set('user', username);
        window.history.replaceState({}, '', url.toString());
    }

    public editPrizeoutComment = (prizeoutUid: string, comment: string | null) => {
        console.log(`Editing comment for ${prizeoutUid} to ${comment}`);
        const standings = this.combinedStandings.getValue();
        const standingToEdit = standings.find(standing => standing.prizeoutUid === prizeoutUid);
        if (standingToEdit) {
            standingToEdit.editComment(comment);
            this.combinedStandings.next(standings);
        } else {
            console.error(`No standing found with prizeoutUid: ${prizeoutUid}`);
        }
    }   

    public editPrizePointsAwarded = (prizeoutUid: string, prizePointsAwarded: number | null) => {
        console.log(`Editing prize points awarded for ${prizeoutUid} to ${prizePointsAwarded}`);
        const standings = this.combinedStandings.getValue();
        const standingToEdit = standings.find(standing => standing.prizeoutUid === prizeoutUid);
        if (standingToEdit) {
            standingToEdit.editPrizePointsAwarded(prizePointsAwarded);
            this.combinedStandings.next(standings);
        } else {
            console.error(`No standing found with prizeoutUid: ${prizeoutUid}`);
        }
    }
}