import { competitorDivision } from "../Common/CompetitorDivision";

export class PrizeoutRecordsChangesTransfer {
    updatedRecords: PrizeoutRecordsChange[];
}

export class PrizeoutRecordsChange {
    prizeoutUid: string;
    eventId: string;
    division: competitorDivision;
    playerId: string;
    firstName: string;
    lastName: string;
    position: number | null;
    prizePointsAwarded: number | null;
    comment: string | null;
    adminName: string | null;
    lastUpdated: string | null;
}