import { useState, useEffect, Fragment } from "react";
import { motion, useAnimationControls } from "framer-motion";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import Loader, { SpinnerType } from "../../components/Spinner/Loader";
import './Standing.scss';
import React from 'react';
import { StandingViewModel } from "./Standing.ViewModel";
import { matchOutcome } from "../../Models/Common/MatchOutcome";
import { Subscription } from "rxjs";

interface StandingProps {
    viewModel: StandingViewModel;
    isHighlightedStanding: boolean;
    toggleHighlightedStanding: (standing: StandingViewModel) => void;
}

const Standing: React.FC<StandingProps> = ({ viewModel, isHighlightedStanding, toggleHighlightedStanding }) => {
    const [isSelected, setSelected] = useState<boolean>(false);
    const animationControls = useAnimationControls();
    const selectedAnimation = useAnimationControls();

    useEffect(() => {
        animationControls.start("onscreen");
        var subs: Subscription[] = [];
        setSelected(isHighlightedStanding);
        return () => subs.forEach(s => s.unsubscribe());
    }, [isHighlightedStanding, animationControls]);

    useEffect(() => {
        if (isSelected) {
            selectedAnimation.start("onscreen");
        } else {
            selectedAnimation.start("offscreen");
        }
    }, [isSelected]);

    if (viewModel === null) {
        return <Loader showMessage={true} spinnerType={SpinnerType.FullPage} />;
    }

    const animationVariants = {
        offscreen: { opacity: 0, y: -10 },
        onscreen: { opacity: 1, y: 0 }
    };

    const selectedAnimationVariantsLeft = {
        offscreen: { opacity: 0, x: 50 },
        onscreen: { opacity: 1, x: 0, transition: { duration: 0.2 } }
    };

    const selectedAnimationVariantsRight = {
        offscreen: { opacity: 0, x: -50 },
        onscreen: { opacity: 1, x: 0, transition: { duration: 0.2 } }
    };


    return (
        <Fragment>
        <motion.div
        className="standing-list-item"
            initial="offscreen"
            animate={animationControls}
            variants={animationVariants}
            transition={{ duration: 0.2 }}
            >
            <div
                className={isSelected ? "standing-card dark-glass-card" : viewModel.isDropped() ? "standing-card dim-glass-card" : "standing-card glass-card"}
                onClick={() => toggleHighlightedStanding(viewModel)}>
                <div className="standing-record-container">
                    <div className="standing-position-box">
                        <label className="glass-h2">{viewModel.position}</label>
                    </div>
                    <label className="glass-h2">{viewModel.wins} / {viewModel.losses} / {viewModel.ties}</label>
                </div>
                <div className="standing-player-details">
                    <label className="glass-h2">{viewModel.fullName()}</label>
                    <label className="glass-h2">{viewModel.wins} / {viewModel.losses} / {viewModel.ties}</label>
                </div>
            </div>
        </motion.div>
        </Fragment>
    )
}

export default Standing;