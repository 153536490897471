import React, { useEffect, useState } from 'react';
import "./PrizeoutButton.scss"
import { FaRegStar, FaStar, FaSync } from 'react-icons/fa';

interface PrizeoutButtonProps {
    prizeKey: string;
    prizeValue: string;
    hoverText: string | null;
    isSyncingChange: boolean;
    isToggled: boolean;
    onToggle: () => void;
    type: PrizeoutButtonType;
}

export enum PrizeoutButtonType {
    Default
}

const PrizeoutButton: React.FC<PrizeoutButtonProps> = ({prizeKey, prizeValue, hoverText, isSyncingChange, isToggled, onToggle, type}) => {
    const [toggledState, setToggledState] = useState<boolean>(false);

    useEffect(() => {
        setToggledState(isToggled);
    }, [isToggled, onToggle]);

    let content = <FaStar />;
    let label: String = "";

    switch (type) {
        case PrizeoutButtonType.Default:
            content = toggledState ? <FaStar className='prizeoutButton-icon selected'/> : <FaRegStar className='prizeoutButton-icon deselected'/>;
            break;
    }

    return (
        <div className={`prizeoutButton-button ${isToggled ? 'paidOut' : ''}`} onClick={(e) => { e.stopPropagation(); onToggle(); }}>
            { hoverText != null &&
            <div className='prizeoutButton-label-container'>
            <label className='prizeoutButton-label'>{label}</label>
            </div>
            }
            <label className='glass-h1'>{prizeValue}</label>
            <label className='glass-caption bold'>{prizeKey}</label>
            {isSyncingChange && <div className='prizeoutButton-corner-element'><FaSync className='sync-badge-icon'/></div>}
        </div>
    );
};

export default PrizeoutButton;