import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NavbarData } from '../components/navbar/Navbar.ViewModel';
import {  DataImportValidationError, DataImportViewModel } from './DataImport.ViewModel';
import { RequestResult } from '../Models/Common/RequestResult';
import { LibraryEvent } from '../Models/Library/LibraryEvent';
import { dataImportType } from '../Models/Common/DataImportType';
import { SlMagicWand } from 'react-icons/sl';
import UploadTracker, { UploadTrackerIcon } from '../components/UploadTracker/UploadTracker';
import GlassCard from '../components/glassTheme/GlassCard';

interface DataImportProps {
  navbarDataSubject: BehaviorSubject<NavbarData>;
}

const DataImport: React.FC<DataImportProps> = ({navbarDataSubject}) => {
  const edViewModel = new DataImportViewModel(navbarDataSubject);

  return (
      <DataImportConstructed viewModel={edViewModel}/>
  );
}

interface DataImportConstructedProps {
  viewModel: DataImportViewModel;
}

const DataImportConstructed: React.FC<DataImportConstructedProps> = ({ viewModel })  => {
  const eventIdPlaceholder = "Enter a unique Event ID..."
  const locationPlaceholder = "Enter the event's host city or similar descriptor."
  const [validationError, setValidationError] = useState<DataImportValidationError | null>(null);
  const [uploadResult, setUploadResult] = useState<RequestResult<LibraryEvent> | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [eventId, setEventId] = useState('');
  const [csvFileContent, setCsvFileContent] = useState<string | null>(null);
  const [importType, setDataImportType] = useState(dataImportType.VgTeamListCsv);
  
  const eventIdValueChange = (value: string) => {
    setEventId(value);
  }

  useEffect(() => {
    var subs: Subscription[] = [];
    subs.push(viewModel.validationError.subscribe(setValidationError));
    subs.push(viewModel.isUploading.subscribe(setIsUploading));
    subs.push(viewModel.uploadResult.subscribe(setUploadResult));
    return () => subs.forEach(s => s.unsubscribe());
  }, []);


  const dataImportTypeChange = (value: dataImportType) => {
    setDataImportType(value);
  }

  const setCsvFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    if (!input || !input.files?.length) {
      return;
    }
    var file = input.files[0];
    var reader = new FileReader()
    reader.onload = async (e: ProgressEvent<FileReader>) => { 
      if (!e.target?.result) {
        setCsvFileContent(null);
        return;
      }
      setCsvFileContent(e.target.result as string);
    };
    reader.readAsText(file)
  }

  return (
    <div className="dataImportDashboard">
    <div className="cardWrapper">
          <GlassCard>
          <div className="cardInsertWithFooter">
              <div className="validationContainer">
                <label className="glass-title">Data Import</label>

                <select className="glass-select" key={importType.valueOf.name} onChange={(e) => {
                    dataImportTypeChange(dataImportType[e.target.value as keyof typeof dataImportType] as dataImportType);
                  }}>
                  {Object.values(dataImportType)
                    .filter((v) => !isNaN(parseInt(v as string)))
                    .map(x => (
                        <option key={dataImportType.name(parseInt(x as string))} value={parseInt(x as string)}>{dataImportType.name(parseInt(x as string) as dataImportType)}</option>
                  ))}
                </select>

                <form>
                  <input
                    className="glass-fileSelect"
                    id="csvFilerSelector"
                    type="file"
                    name="file"
                    accept=".csv"
                    onChange={(e) => setCsvFile(e)}
                    style={{ display: "block", margin: "10px auto" }}
                  />
                </form>

                <input className="glass-textarea" placeholder={eventIdPlaceholder} onChange={(e) => eventIdValueChange(e.target.value)}></input>

                {(uploadResult == null && !isUploading) &&
                  <div className='validationContainer'>
                    {validationError == DataImportValidationError.initialState && <label className="validationMessage"><i>This information directly affects stream data. Please enter carefully-formatted and accurate values.</i></label>}
                    {validationError == DataImportValidationError.eventIdError && <label className="validationMessage"><i>Event ID invalid - use letters or numbers only, limited to 15 characters.</i></label>}
                    {validationError == DataImportValidationError.csvFileError && <label className="validationMessage"><i>CSV file not found or invalid.</i></label>}
                    {validationError == DataImportValidationError.importError && <label className="validationMessage"><i>Failed to import data.</i></label>}
                    {validationError == DataImportValidationError.otherError && <label className="validationMessage"><i>Unexpected problem with creating event.</i></label>}
                  </div>
                }
                {(uploadResult != null && uploadResult?.isSuccess()) ?
                    <a className="glass-body" href={"/web/events/"+eventId} target="">
                      <button className="createButton">Import Complete <SlMagicWand className="glass-button-icon-right"/></button>
                    </a>
                  : 
                  <button className="createButton"
                    disabled={validationError != null}
                    onClick={() => viewModel.requestImport(eventId, csvFileContent, importType)}>
                      Start Import <SlMagicWand className="glass-button-icon-right"/>
                  </button>
                }
              </div>
            </div>
          </GlassCard>
        </div>
      <div className="card-footer">
        { viewModel &&
          <UploadTracker
            isUploading={isUploading}
            uploadResult={uploadResult}
            defaultIcon={UploadTrackerIcon.Document}
            defaultTitle={"Import data"}
            defaultSubtitle={"Upload information will appear here."}
            uploadMessage="Processing import file..."/>
        }
      </div>
    </div>
  );
}

export default DataImport;