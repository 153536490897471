import { useEffect, Fragment } from "react";
import { motion } from "framer-motion";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import './StandingsDashboard.scss';
import React from 'react';
import { StandingsDashboardViewModel } from "./StandingsDashboard.ViewModel";
import { useParams } from "react-router-dom";
import { BehaviorSubject, Subscription } from "rxjs";
import { NavbarData } from "../../components/navbar/Navbar.ViewModel";
import StandingsList from "../StandingsList/StandingsList";
import { QueryParamService } from "../../Services/QueryParamService";

const animationVariants = {
    offscreen: { delay: 0, opacity: 0, scale: 0.6, transition: { duration: 0.5 } },
    onscreen: { delay: 0, opacity: 1, scale: 1, transition: { duration: 0.2 } }
};

interface StandingsDashboardProps {
    navbarDataSubject: BehaviorSubject<NavbarData>;
}

const StandingsDashboard: React.FC<StandingsDashboardProps> = ({navbarDataSubject}) => {
    const eventId: string = useParams().eventId ?? "";
    var round: number | null = Number(useParams().roundNumber) ?? null;

    const queryParams = new URLSearchParams(window.location.search);
    const division = QueryParamService.getDivision(queryParams);

    if (round === null) {
        round = null;
    }
    const pdViewModel = new StandingsDashboardViewModel(eventId, navbarDataSubject, division, round);

    return (
        <StandingsDashboardConstructed viewModel={pdViewModel}/>
    );
}

interface StandingsDashboardConstructedProps {
    viewModel: StandingsDashboardViewModel;
}

const StandingsDashboardConstructed: React.FC<StandingsDashboardConstructedProps> = ({ viewModel })  => {
    // animationControl = useAnimationControls()

    useEffect(() => {
        var subs: Subscription[] = [];
        return () => subs.forEach(s => s.unsubscribe());
    }, []);

    return (
        <Fragment>
            <motion.div
                initial="onscreen"
                // animate={this.animationControl}
                variants={animationVariants}
                className="standings-content">
                <StandingsList
                    viewModelSubject={viewModel.StandingsSubject}
                    highlightedStanding={viewModel.highlightedStanding}
                    toggleHighlightedStanding={viewModel.toggleHighlightedStanding}/>
            </motion.div>
        </Fragment>
    );
}

export default StandingsDashboard;