import { useEffect, Fragment, useState } from "react";
import { motion } from "framer-motion";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import './ScorekeeperDashboard.scss';
import React from 'react';
import Loader, { SpinnerType } from "../../components/Spinner/Loader";
import { scorekeeperDashboardViewModel } from "./ScorekeeperDashboard.ViewModel";
import { useParams } from "react-router-dom";
import { BehaviorSubject, Subscription } from "rxjs";
import Uploader from "../Uploader/Uploader";
import { NavbarData } from "../../components/navbar/Navbar.ViewModel";
import { loadingState } from "../../Models/Common/LoadingState";
import EventDetailsCard from "../EventDetailsCard/EventDetailsCard";
import { QueryParamService } from "../../Services/QueryParamService";

const animationVariants = {
    offscreen: { delay: 0, opacity: 0, scale: 0.6, transition: { duration: 0.5 } },
    onscreen: { delay: 0, opacity: 1, scale: 1, transition: { duration: 0.2 } }
};

interface ScorekeeperDashboardProps {
    navbarDataSubject: BehaviorSubject<NavbarData>;
}

const ScorekeeperDashboard: React.FC<ScorekeeperDashboardProps> = ({navbarDataSubject}) => {
    const eventId: string = useParams().eventId ?? "";
    var round: number | null = Number(useParams().roundNumber) ?? null;

    if (round === null) {
        round = null;
    }
    const pdViewModel = new scorekeeperDashboardViewModel(eventId, navbarDataSubject, round);

    console.log("ScorekeeperDashboard.tsx: pdViewModel: ", pdViewModel);
    return (
        <ScorekeeperDashboardConstructed viewModel={pdViewModel}/>
    );
}

interface ScorekeeperDashboardConstructedProps {
    viewModel: scorekeeperDashboardViewModel;
}

const ScorekeeperDashboardConstructed: React.FC<ScorekeeperDashboardConstructedProps> = ({ viewModel })  => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    QueryParamService.alertOnRefresh();

    useEffect(() => {
        var subs: Subscription[] = [];
        subs.push(viewModel.loadingStateSubject.subscribe((state) => setIsLoading(state !== loadingState.hidden)));
        return () => subs.forEach(s => s.unsubscribe());
    }, [viewModel]);

    if (viewModel?.eventViewModelSubject === null) {
        return <Loader showMessage={true} spinnerType={SpinnerType.FullPage} />;
    }

    if (isLoading) {
        return <Loader showMessage={true} spinnerType={SpinnerType.FullPage} loadingStateSubject={viewModel.loadingStateSubject}/>;
    }

    return (
        <div className="scorekeeper-dashboard">

            <EventDetailsCard
                eventId={viewModel.eventId}
                eventViewModelSubject={viewModel.eventViewModelSubject}/>
            <Uploader
                eventId={viewModel.eventId}
                apiSampleSubject = {viewModel.apiSampleViewModel}
                eventDataSubject={viewModel.eventViewModelSubject}
                tournamentFileSubject = {viewModel.selectedTournamentFileViewModel}
                standingsSubject = {viewModel.selectedStandingsViewModel}
                switchResultsTab = {viewModel.switchResultsTab}
                updateSelectedTournamentFile = {viewModel.updateSelectedTournamentFile}
                updateSelectedStandingsValue = {viewModel.updateSelectedStandingsValue}
                standingsReportDivisionChange = {viewModel.standingsReportDivisionChange}
                manuallySetSelectedStandingsValue = {viewModel.manuallySetStandingsReportRoundValue}
                uploadTournamentFile = {viewModel.uploadTournamentFile}
                uploadFusionImport={viewModel.uploadFusionImport}
                exportTdf = {viewModel.exportTdf}
                uploadStandings = {viewModel.uploadStandings}
                editFusionDataChannel={viewModel.editFusionDataChannel}
                localFusionDataChannelSubject={viewModel.localFusionDataChannelSubject}
                fusionLogbookBundlesSubject={viewModel.fusionLogbookBundlesSubject}
                />
        </div>
    )
}

export default ScorekeeperDashboard;