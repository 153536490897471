import { useState, useEffect, Fragment } from "react";
import { motion, useAnimationControls } from "framer-motion";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import Loader, { SpinnerType } from "../../components/Spinner/Loader";
import './PrizeoutEventSelector.scss';
import { PrizeoutEventSelectorViewModel } from "./PrizeoutEventSelector.ViewModel";
import { Subscription } from "rxjs";
import { CommonFormatsService } from "../../Services/CommonFormatsService";
import { gameType } from "../../Models/Common/GameType";

interface PrizeoutEventSelectorProps {
    viewModel: PrizeoutEventSelectorViewModel;
}

const PrizeoutEventSelector: React.FC<PrizeoutEventSelectorProps> = ({ viewModel }) => {

    const [selectedEvents, setSelectedEvents] = useState(viewModel.selectedEvents.value)
    const [selectableEvents, setSelectableEvents] = useState(viewModel.selectableEvents.value)
    const [isLoading, setIsLoading] = useState(viewModel.isLoading.value)
    const [searchTerm, setSearchTerm] = useState('')
    const [showOnlySelected, setShowOnlySelected] = useState(false)

    useEffect(() => {
        var subs: Subscription[] = [];
        subs.push(viewModel.selectedEvents.subscribe((events) =>
            {
                console.log("Events updated: ", events);
                setSelectedEvents([...events]);
            }));
        subs.push(viewModel.selectableEvents.subscribe((events) => setSelectableEvents([...events])));
        subs.push(viewModel.isLoading.subscribe((value) => setIsLoading(value)));
        return () => subs.forEach(s => s.unsubscribe());
    }, [viewModel]);

    const filteredEvents = selectableEvents.filter(event => 
        event.eventId.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const clearSelection = () => {
        selectedEvents.forEach(event => viewModel.toggleEventSelection(event));
        setSelectedEvents([]);
    };

    if (viewModel === null) {
        return <Loader showMessage={true} spinnerType={SpinnerType.FullPage} />;
    }

    return (
        <Fragment>
            <motion.div
            className="eventSelector-content"
                initial="offscreen"
                transition={{ duration: 0.2 }}>
                <div className="eventSelector-card">
                    <div className="eventSelector-header">
                        <label className="eventSelector-h1">Select one or many events to prize out</label>
                        <div className="eventSelector-header-controls">
                            <input 
                            type="text" 
                            placeholder="Search by Event ID" 
                            value={searchTerm} 
                            onChange={(e) => setSearchTerm(e.target.value)} 
                            className="eventSelector-search"
                            />
                            <button 
                                className="eventSelector-filter-button" 
                                onClick={() => setShowOnlySelected(!showOnlySelected)}>
                                {showOnlySelected ? "Show All" : "Show Selected"}
                            </button>
                        </div>
                    </div>
                    {isLoading ?
                        <Loader showMessage={true} spinnerType={SpinnerType.Circular} />
                    :
                        <div className="eventSelector-list">
                            {filteredEvents.length === 0 && !isLoading ? (
                                <label className="glass-body">Search returned no results</label>
                            ) : (
                                (showOnlySelected ? selectedEvents : filteredEvents).slice().reverse().map((event, index) => {
                                    return (
                                        <div key={index} className="event-item" onClick={() => viewModel.toggleEventSelection(event)}>
                                            <input
                                                type="checkbox"
                                                checked={selectedEvents.some(selectedEvent => selectedEvent.eventId === event.eventId)}
                                                onChange={() => viewModel.toggleEventSelection(event)}
                                                onClick={(e) => e.stopPropagation()}
                                            />
                                            <div className="data-pair">
                                                <label className="glass-caption">{event.eventId} - {gameType.shortName(event.details.gameType)}</label>
                                                <label className="glass-h2">{CommonFormatsService.eventName(event.details)}</label>
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    }
                    <div className="eventSelector-footer">
                        <button
                            className="eventSelector-button"
                            onClick={() => clearSelection()}
                            disabled={selectedEvents.length === 0}>Clear selection</button>
                        <label className="glass-body italic">{selectedEvents.length} events selected</label>
                        <button 
                            className="eventSelector-button" 
                            onClick={() => viewModel.didFinishSelecting()} 
                            disabled={selectedEvents.length === 0}>Next</button>
                    </div>
                </div>
            </motion.div>
        </Fragment>
    )
}

export default PrizeoutEventSelector;