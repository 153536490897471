
export enum LogbookMessageLevel {
        Debug = 0,
        Info = 1,
        Warning = 2,
        Error = 3,
}

export namespace LogbookMessageLevel {
        export function name(state: LogbookMessageLevel): string {
        switch (state) {
                case LogbookMessageLevel.Debug:
                        return "Debug";
                case LogbookMessageLevel.Info:
                        return "Info";
                case LogbookMessageLevel.Warning:
                        return "Warning";
                case LogbookMessageLevel.Error:
                        return "Error";
                }
        }
        export function simpleName(state: LogbookMessageLevel): string {
                switch (state) {
                        case LogbookMessageLevel.Debug:
                                return "debug";
                        case LogbookMessageLevel.Info:
                                return "info";
                        case LogbookMessageLevel.Warning:
                                return "warning";
                        case LogbookMessageLevel.Error:
                                return "error";
                        }
                }
}