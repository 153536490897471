export enum eventType {
        Regional = 0,
        SpecialEvent = 1,
        International = 2,
        Worlds = 3,
        Other = 4,
        SideEvent = 5
}

export namespace eventType {
        export function name(state: eventType): string {
        switch (state) {
                case eventType.Regional:
                        return "Regional";
                case eventType.SpecialEvent:
                        return "Special Event";
                case eventType.International:
                        return "International";
                case eventType.Worlds:
                        return "Worlds";
                case eventType.Other:
                        return "Other";
                case eventType.SideEvent:
                        return "Side Event";
        }
        }
}