import { BehaviorSubject } from "rxjs";
import { NavbarSyncToolIcon } from "./NavbarSyncTool";
import { RequestResult } from "../../Models/Common/RequestResult";

export class navbarViewModel {
    title: string;
    subtitle: string;

    constructor(data: NavbarData) {
        this.title = data.title;
        this.subtitle = data.subtitle;
        console.log("Navbar view model created with " + this.title + " and " + this.subtitle);
    }
}

export class NavbarProperties {
    syncToolConfig: SyncToolConfig;
    data: BehaviorSubject<NavbarData>;

    constructor() {
        this.syncToolConfig = new SyncToolConfig();
        this.data = new BehaviorSubject<NavbarData>(new NavbarData());
    }
}

export class NavbarData {
    title: string = "";
    subtitle: string = "";

    constructor(title: string = "", subtitle: string = "") {
        if (title == "") {
            this.title = "Stream Data Tools";
        } else {
            this.title = title;
        }

        if (subtitle == "") {
            this.subtitle = "";
        } else {
            this.subtitle = subtitle;
        }
    }
}

export class SyncToolConfig {
    isSyncFeatureEnabled: BehaviorSubject<boolean>;
    isSyncActive: BehaviorSubject<boolean>;
    data: BehaviorSubject<SyncToolConfigData>;

    constructor() {
        this.isSyncFeatureEnabled = new BehaviorSubject<boolean>(false);
        this.isSyncActive = new BehaviorSubject<boolean>(false);
        this.data = new BehaviorSubject<SyncToolConfigData>(new SyncToolConfigData());
    }
}

export class SyncToolConfigData {
    isFetching: boolean;
    result: RequestResult<any> | null;
    defaultIcon: NavbarSyncToolIcon;
    title: string;
    caption: string;
    message: string;

    constructor(
        isFetching: boolean = false,
        uploadResult: RequestResult<any> | null = null,
        defaultIcon: NavbarSyncToolIcon = NavbarSyncToolIcon.StreamData,
        title: string = "",
        caption: string = "",
        message: string = ""
    ) {
        this.isFetching = isFetching;
        this.result = uploadResult;
        this.defaultIcon = defaultIcon;
        this.title = title;
        this.caption = caption;
        this.message = message;
    }
}