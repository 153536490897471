import { eventConfig } from "../../Models/Common/EventConfig";
import { apiPrizeoutStanding, apiPrizeoutStandingsDivision, apiPrizeoutStandingsEvent, PrizeoutRecordEditAction } from "../../Models/Inbound/ApiPrizeoutStandingsEvent";
import { PrizeoutRecordsChange } from "../../Models/Inbound/PrizeoutRecordsChangesTransfer";

export class PrizeoutStandingViewModel {
    // Data from parent event
    prizeoutUid: string;
    eventId: string;
    division: number;
    standingsLastUpdated: string;
    round: number;
    isFinal: boolean;

    // Data from server
    position: number | null;
    playerId: string | null;
    firstName: string;
    lastName: string;
    countryCode: string | null;
    wins: number;
    losses: number;
    ties: number;
    matchPoints: number | null;
    dropRound: number | null;
    editHistory: PrizeoutRecordEditAction[];

    // Prizing data from server (read-only)
    prizePointsAwarded: number | null;

    // Prizing data from server (read-write)
    prizePointsDue: number | null;
    prizingLastUpdated: string | null;
    comment: string | null;

    // Local prizing data changes (edited)
    localPrizePointsAwarded: number | null;
    localComment: string | null;
    localEditTime: string | null;

    constructor(apiStanding: apiPrizeoutStanding, event: apiPrizeoutStandingsEvent, division: apiPrizeoutStandingsDivision) {
        this.setServerData(apiStanding, event, division);
        this.localComment = null;
        this.localPrizePointsAwarded = null;
        this.localEditTime = null;
    }

    public isDropped(): boolean {
        return this.dropRound !== null;
    }

    public fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    public formattedPosition(): string {
        if (this.position === null) {
            return "N/A";
        }
        const suffix = ["th", "st", "nd", "rd"];
        const value = this.position % 100;
        return this.position + (suffix[(value - 20) % 10] || suffix[value] || suffix[0]);
    }

    public editPrizePointsAwarded(value: number | null) {
        if (value === this.localPrizePointsAwarded) {
            this.localPrizePointsAwarded = null;
            return;
        }
        this.localPrizePointsAwarded = value;
        this.localEditTime = new Date().toISOString();
    }

    public editComment(value: string | null) {
        if (value === "" || value === " ") { value = null }
        if (value === this.comment || (value === null && this.comment === null)) {
            this.localComment = null;
            return;
        }
        this.localComment = value;
        this.localEditTime = new Date().toISOString();
    }

    public updateServerData(apiStanding: apiPrizeoutStanding, event: apiPrizeoutStandingsEvent, division: apiPrizeoutStandingsDivision) {
        // Reset local changes if they match the server data
        if (this.localPrizePointsAwarded !== null && this.localPrizePointsAwarded == apiStanding.prizePointsAwarded) {
            this.localPrizePointsAwarded = null;
        }
        if (this.localComment !== null && this.localComment == apiStanding.comment) {
            this.localComment = null;
        }
        if (this.localEditTime !== null && this.localEditTime == apiStanding.lastUpdated) {
            this.localEditTime = null;
        }
        this.setServerData(apiStanding, event, division);
    }

    public isEdited(): boolean {
        return this.localPrizePointsAwarded !== null || this.localComment !== null;
    }

    public isFullyPrizedOut(): boolean {
        const due = this.prizePointsDue ?? 0;
        const awarded = this.prizePointsAwarded ?? 0;
        const localAwarded = this.localPrizePointsAwarded ?? awarded;
        return due > 0 && localAwarded >= due;
    }

    public toPrizeoutRecordsChangeIfEdited(adminName: string): PrizeoutRecordsChange | null {
        if (this.isEdited() === false) {
            return null;
        }
        return {
            prizeoutUid: this.prizeoutUid,
            eventId: this.eventId,
            division: this.division,
            playerId: this.playerId ?? '',
            firstName: this.firstName,
            lastName: this.lastName,
            position: this.position,
            prizePointsAwarded: this.localPrizePointsAwarded,
            comment: this.localComment,
            adminName: adminName,
            lastUpdated: this.localEditTime
        };
    }

    private setServerData(apiStanding: apiPrizeoutStanding, event: apiPrizeoutStandingsEvent, division: apiPrizeoutStandingsDivision) {
        this.position = apiStanding.position;
        this.playerId = apiStanding.playerId;
        this.firstName = apiStanding.firstName;
        this.lastName = apiStanding.lastName;
        this.countryCode = apiStanding.countryCode;
        this.wins = apiStanding.wins;
        this.losses = apiStanding.losses;
        this.ties = apiStanding.ties;
        this.matchPoints = apiStanding.matchPoints;
        this.dropRound = apiStanding.dropRound;

        this.prizePointsDue = apiStanding.prizePointsDue;
        this.prizePointsAwarded = apiStanding.prizePointsAwarded;
        this.prizingLastUpdated = apiStanding.lastUpdated;
        this.comment = apiStanding.comment;
        this.editHistory = apiStanding.editHistory;

        this.prizeoutUid = apiStanding.prizeoutUid;
        this.eventId = event.eventId;
        this.standingsLastUpdated = division.lastUpdated;
        this.division = division.division;
        this.round = division.round;
        this.isFinal = division.isFinal;
    }
}