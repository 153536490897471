import { LogbookMessageViewModel } from "../../../components/UploadTracker/LogbookViewModel";
import { LogbookMessageLevel as logbookMessageLevel } from "../../Common/LogbookMessageLevel";
import { LogbookMessageType as logbookMessageType } from "../../Common/LogbookMessageType";

export class logbookMessage {
  id: string;
  type: logbookMessageType;
  level: logbookMessageLevel;
  timestamp: string;
  message: string;
  details: string | null;
}

export namespace logbookMessage {
  export function toViewModel(value: logbookMessage): LogbookMessageViewModel {
    return {
      id: value.id,
      type: value.type,
      level: value.level,
      timestamp: value.timestamp,
      message: value.message,
      details: value.details,
      compactMessage: (instance: logbookMessage) => `${instance.timestamp}: ${instance.message}`
    };
  }
}