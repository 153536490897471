import React, { useState, useEffect, useRef } from 'react';
import { getFileHandle, removeFileHandle, saveFileHandle } from '../../Services/FileSystemAccessService';
import './FusionControls.scss';
import { FaFileArrowUp } from "react-icons/fa6";
import { FaPlayCircle, FaPauseCircle, FaSyncAlt } from "react-icons/fa";
import IconButton, { IconButtonType } from '../../components/IconButton/IconButton';
import { fusionDataChannel } from '../../Models/Common/FusionDataChannel';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PiExportBold } from "react-icons/pi";

interface FusionControlsProps {
    updateSelectedTournamentFile: (file: string) => void;
    isFinalUpload: boolean;
    uploadTournamentFile: (shouldStoreTdf: boolean, isFinalUpload: boolean) => void;
    uploadFusionImport : (shouldResetCore: boolean, shouldResetSupportCache: boolean, channel: number, isDataImportOnly: boolean, skipValidation: boolean) => void;
    editFusionDataChannel: (channel: fusionDataChannel | null) => void;
    localFusionDataChannelSubject: BehaviorSubject<fusionDataChannel | null>;
    exportTdf : (channel: fusionDataChannel | null) => void;
    toggleShowDebugLogs: (value: boolean) => void;

}

const FusionControls: React.FC<FusionControlsProps> = ({
    updateSelectedTournamentFile,
    isFinalUpload,
    uploadTournamentFile,
    uploadFusionImport,
    editFusionDataChannel,
    localFusionDataChannelSubject,
    exportTdf,
    toggleShowDebugLogs
}) => {
    const selectedFileId = 'tournamentFile';
    const [fileHandle, setFileHandle] = useState<FileSystemFileHandle | null>(null);
    const [fileName, setFileName] = useState<string>('');
    const [comparisonFileContent, setComparisonFileContent] = useState<string>('');
    const [fileAccessGranted, setFileAccessGranted] = useState<boolean>(false);
    const [fileScanFrequency, setFileScanFrequency] = useState<number>(5);
    const [autoSmallChangeUploadFrequency, setAutoSmallChangeUploadFrequency] = useState<number>(180); // Every three minutes
    const [autoLargeChangeUploadFrequency, setAutoLargeChangeUploadFrequency] = useState<number>(5); // Same as file scan (immediate)
    const [autoHardUploadFrequency, setAutoHardUploadFrequency] = useState<number>(900); // Every fifteen minutes
    const [isFusionEnabled, setIsFusionEnabled] = useState<boolean>(false);
    const [isFastMode, setIsFastMode] = useState<boolean>(false);
    const [shouldResetCore, setShouldResetCore] = useState<boolean>(false);
    const [shouldResetSupportCache, setShouldResetSupportCache] = useState<boolean>(false);
    const [fusionTimeLeft, setFusionTimeLeft] = useState<number>(0);
    const [featureError, setFeatureError] = useState<boolean>(false);
    const [fileReadError, setFileReadError] = useState<boolean>(false);
    const [localFusionDataChannel, setLocalFusionDataChannel] = useState<fusionDataChannel | null>(null);
    const [isShowingAdvancedControls, setIsShowingAdvancedControls] = useState<boolean>(false);
    const [showDebugLogs, setShowDebugLogs] = useState<boolean>(false);
    const [skipValidation, setSkipValidation] = useState<boolean>(false);
    const [isDataImportOnly, setIsDataImportOnly] = useState<boolean>(false);
    const serializer = new XMLSerializer();
    const workerRef = useRef<Worker | null>(null);

    useEffect(() => {
        var subs: Subscription[] = [];
        subs.push(localFusionDataChannelSubject.subscribe((value) => setLocalFusionDataChannel(value)));
    }, [localFusionDataChannelSubject]);

    useEffect(() => {
        function uploadIfSmallChange() {
            if (fileHandle) {
                readAndUploadFile(fileHandle, false, false);
            }
        }

        function uploadIfLargeChange() {
            if (fileHandle) {
                readAndUploadFile(fileHandle, false, true);
            }
        }

        function uploadHard() {
            uploadFileImmediately();
        }

        if (fileAccessGranted && isFusionEnabled) {
            if (!workerRef.current) {
                workerRef.current = new Worker(new URL('./FusionUploadWorker.tsx', import.meta.url));
                workerRef.current.onmessage = (e: MessageEvent) => {
                    const { type, timeLeft } = e.data;
                    // Outside the switch so that they can happen in parallel
                    if (type.includes('TIME_LEFT')) {
                        setFusionTimeLeft(timeLeft);
                    }
                    if (type.includes('FILE_SCAN')) {
                        console.log("File scan at " + new Date().toLocaleTimeString());
                        // For use when reading and printing values to the UI
                    }
                    if (type.includes('SMALL_CHANGE')) {
                        console.log("Small change check at " + new Date().toLocaleTimeString());
                        uploadIfSmallChange();
                    }
                    if (type.includes('LARGE_CHANGE')) {
                        console.log("Large change check at " + new Date().toLocaleTimeString());
                        uploadIfLargeChange();
                    }
                    if (type.includes('HARD_UPLOAD')) {
                        console.log("Hard upload at " + new Date().toLocaleTimeString());
                        uploadHard();
                    }
                };
            }

            console.log("Starting working with frequencies: " + fileScanFrequency + ", " + autoSmallChangeUploadFrequency + ", " + autoLargeChangeUploadFrequency + ", " + autoHardUploadFrequency);
            workerRef.current.postMessage({
                fileScanFrequency,
                autoSmallChangeUploadFrequency,
                autoLargeChangeUploadFrequency,
                autoHardUploadFrequency,
            });

            return () => {
                if (workerRef.current) {
                    workerRef.current.postMessage('STOP');
                    workerRef.current.terminate();
                    workerRef.current = null;
                }
            };
        }
    }, [fileAccessGranted, isFusionEnabled, isFastMode, comparisonFileContent]);

    const toggleIsFastMode = async (isFastMode: boolean) => {
        setIsFastMode(isFastMode);
        setFileScanFrequency(isFastMode ? 3 : 5);
        setAutoSmallChangeUploadFrequency(isFastMode ? 3 : 180);
        setAutoLargeChangeUploadFrequency(isFastMode ? 3 : 5); // Same as file scan
        setAutoHardUploadFrequency(isFastMode ? 180 : 900);
    }
    const toggleResetCore = async (shouldResetCore: boolean) => {
        setShouldResetCore(shouldResetCore);
    }
    const toggleResetSupportCache = async (shouldResetSupportCache: boolean) => {
        setShouldResetSupportCache(shouldResetSupportCache);
    }
    const toggleSkipValidation = async (skipValidation: boolean) => {
        setSkipValidation(skipValidation);
    }
    const toggleIsDataImportOnly = async (isDataImportOnly: boolean) => {
        setIsDataImportOnly(isDataImportOnly);
    }
    const updateToggleDebugLogs = async (showDebugLogs: boolean) => {
        setShowDebugLogs(showDebugLogs);
        toggleShowDebugLogs(showDebugLogs);
    }

    const requestFileAccess = async () => {
        try {
            if (!(window as any).showOpenFilePicker) {
                setFeatureError(true);
                return;
            }
            const [handle] = await (window as any).showOpenFilePicker();
            setFileHandle(handle);
            await saveFileHandle(selectedFileId, handle);
            await readFile(handle);
            setFileAccessGranted(true);
            setFileName(handle.name);
            setFileReadError(false);
            setIsFusionEnabled(false);
        } catch (error) {
            console.error(error);
        }
    };

    const revokeFileAccess = async () => {
        await removeFileHandle(selectedFileId);
        setFileHandle(null);
        setFileName('');
        setComparisonFileContent('');
        setFileAccessGranted(false);
    };

    const readFile = async (handle: FileSystemFileHandle) => {
        const file = await handle.getFile();
        const newFileContent = await file.text();
        updateSelectedTournamentFile(newFileContent);
    }

    const removeTimeElements = (xmlString: string): string => {
        try {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xmlString, "application/xml");

            const tagsToRemove = ["timeelapsed", "timeleft"];
            tagsToRemove.forEach(tag => {
                const elements = xmlDoc.getElementsByTagName(tag);
                Array.from(elements).forEach(element => {
                    element.parentNode?.removeChild(element);
                });
            });
            return serializer.serializeToString(xmlDoc);
        } catch (error) {
            console.error("Error processing XML:", error);
            return xmlString; // Return the original string if an error occurs
        }
    };

    const readAndUploadFile = async (
        handle: FileSystemFileHandle,
        uploadImmediately: boolean = false,
        onlyUploadIfLargeChange: boolean = false
    ) => {
        try {
            console.log("Reading file at " + new Date().toLocaleTimeString());
            const file = await handle.getFile();
            const newFileContent = await file.text();
            const newFileWithoutTimestamps = removeTimeElements(newFileContent);
            setFileReadError(false);

            // Check if we should upload immediately
            if (uploadImmediately) {
                console.log("Hard update, uploading file at " + new Date().toLocaleTimeString());
                processUpload(newFileContent, true);
                setComparisonFileContent(newFileWithoutTimestamps);
                return;
            }

            // Check for a significant change in file content size
            if (onlyUploadIfLargeChange) {
                console.log("Large check at " + new Date().toLocaleTimeString());
                if (Math.abs(comparisonFileContent.length - newFileWithoutTimestamps.length) > 20) {
                    console.log("Large change detected, uploading file. CC: " + (comparisonFileContent.length - newFileWithoutTimestamps.length) + " at " + new Date().toLocaleTimeString());
                    processUpload(newFileContent, true);
                    setComparisonFileContent(newFileWithoutTimestamps);
                    return;
                }
            } else {
                // Check if file content has changed
                console.log("Small check at " + new Date().toLocaleTimeString());
                const areDifferent = await areStringsDifferent(comparisonFileContent, newFileWithoutTimestamps);
                if (areDifferent) {
                    console.log("Small change detected, uploading file at " + new Date().toLocaleTimeString());
                    processUpload(newFileContent, false);
                    setComparisonFileContent(newFileWithoutTimestamps);
                    return;
                }
            }
        } catch (error) {
            console.error("Error reading or uploading file:", error);
            setFileReadError(true);
        }
    };

    const hashString = async (input: string): Promise<string> => {
        const encoder = new TextEncoder();
        const data = encoder.encode(input);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    };

    const areStringsDifferent = async (string1: string, string2: string): Promise<boolean> => {
        const [hash1, hash2] = await Promise.all([hashString(string1), hashString(string2)]);
        console.log("Hash1: " + hash1);
        console.log("Hash2: " + hash2);
        console.log("diff: " + (hash1 !== hash2));
        return hash1 !== hash2;
    };
    
    const uploadFileImmediately = async () => {
        if (fileHandle) {
            readAndUploadFile(fileHandle, true);
        }
    }
    
    const processUpload = (newFileContent: string, shouldStoreTdf: boolean) => {
        updateSelectedTournamentFile(newFileContent);
        if (localFusionDataChannel !== null) {
            uploadFusionImport(shouldResetCore, shouldResetSupportCache, localFusionDataChannel, isDataImportOnly, skipValidation);
        }
    }
    
    return (
        <div className='fusionControlPanel'>
            <label className='glass-h2'>Fusion Upload</label>
            {!fileAccessGranted ? <label className='glass-caption italic'>Please use Chrome or Edge browser</label> : null}
            <br/>
            <div className='fusionControlSection'>
                <select
                    className="fusionChannelSelect"
                    value={localFusionDataChannel !== null ? localFusionDataChannel.toString() : ""}
                    onChange={(e) => {
                        const selectedValue = parseInt(e.target.value);
                        editFusionDataChannel(selectedValue as fusionDataChannel);
                    }}>
                    {Object.keys(fusionDataChannel)
                        .filter((key) => !isNaN(Number(key)))
                        .map((key) => {
                            const value = Number(key) as fusionDataChannel;
                            return (
                                <option key={key} value={value}>
                                    {fusionDataChannel.name(value)}
                                </option>
                            );
                        })}
                </select>
                <div className='fusion-data-pair'>
                    <label className='glass-caption'>Download files:</label>
                    <div className='downloadButtonSection'>
                        <button className='tdfDownloadButton' onClick={() => exportTdf(null)}>Core TDF <PiExportBold className="glass-button-icon-right"/></button>
                        <button className='tdfDownloadButton' onClick={() => exportTdf(localFusionDataChannel)}>Channel TDF <PiExportBold className="glass-button-icon-right"/></button>
                    </div>
                </div>
                {featureError ? <label className='glass-body bold'>⚠️ Configuration not supported</label> : null}
                {!fileAccessGranted ? <button className='fileSelectButton' onClick={requestFileAccess}>Grant File Access <FaFileArrowUp className="glass-button-icon-right"/></button> : null}
                {fileAccessGranted ?
                <div className='fusionControls'>
                    <div className='fileSelectControls'>
                        <label className='glass-body bold'>./{fileName}</label>
                        <IconButton isEnabled={true} hasShadow={false} onPress={revokeFileAccess} iconType={IconButtonType.Trash} label="Remove reference to file"/>
                    </div>
                    <div className='timerControlsDataSections'>
                        <div className='syncIconPair'>
                    <div className='timerControlsData'>
                            <label className='glass-body bold'>Update frequencies</label>
                            <label className='glass-body'>File scan: {isFastMode ? '3 seconds' : '5 seconds'}</label>
                            <label className='glass-body'>New rounds: immediate</label>
                            <label className='glass-body'>Match results: {isFastMode ? 'immediate' : '3 minutes'}</label>
                            <label className='glass-body'>Unconditional: {isFastMode ? '3 minutes' : '15 minutes'}</label>
                            {isFusionEnabled ? <label className='glass-body bold'>Next file scan in {fusionTimeLeft}s</label> : null}
                            {fileReadError ? <label className='glass-body bold'>Error reading file</label> : null}
                    </div>
                            {isFusionEnabled ? <FaSyncAlt className='syncIcon'/> : null}
                            </div>

                    <div className='timerControlsDataSections'>
                            <div className='overrideControls'>
                                <div className='toggleBox'>
                                    <label className="glass-switch">
                                    <input
                                    type="checkbox"
                                    checked={isFastMode}
                                    onChange={(e) => toggleIsFastMode(!isFastMode)}/>
                                    <i></i>
                                    </label>
                                    <label className="glass-body">Fast mode</label>
                                </div>
                                <div className='toggleBox'>
                                    <label className="glass-switch">
                                    <input
                                    type="checkbox"
                                    checked={shouldResetCore}
                                    onChange={(e) => toggleResetCore(!shouldResetCore)}/>
                                    <i></i>
                                    </label>
                                    <label className="glass-body">Reset Core file</label>
                                </div>
                                <div className='toggleBox'>
                                    <label className="glass-switch">
                                    <input
                                    type="checkbox"
                                    checked={shouldResetSupportCache}
                                    onChange={(e) => toggleResetSupportCache(!shouldResetSupportCache)}/>
                                    <i></i>
                                    </label>
                                    <label className="glass-body">Reset channel cache</label>
                                </div>
                            </div>
                            <label
                                className='glass-body clickable'
                                onClick={() => setIsShowingAdvancedControls(!isShowingAdvancedControls)}>
                                {isShowingAdvancedControls ? 'Hide Advanced Controls' : 'Show Advanced Controls'}
                            </label>
                            {isShowingAdvancedControls && (
                                <div className='advancedControls'>
                                    <div className='toggleBox'>
                                        <label className="glass-switch">
                                        <input
                                        type="checkbox"
                                        checked={isDataImportOnly}
                                        onChange={(e) => toggleIsDataImportOnly(!isDataImportOnly)}/>
                                        <i></i>
                                        </label>
                                        <div className="data-pair">
                                            <label className="glass-body">Skip saving file</label>
                                            <label className="glass-caption">Only import pairings, standings, etc.</label>
                                        </div>
                                    </div>
                                    <div className='toggleBox'>
                                        <label className="glass-switch">
                                        <input
                                        type="checkbox"
                                        checked={skipValidation}
                                        onChange={(e) => toggleSkipValidation(!skipValidation)}/>
                                        <i></i>
                                        </label>
                                        <div className="data-pair">
                                            <label className="glass-body">Skip validation</label>
                                            <label className="glass-caption">Resulting file after merge will not be validated</label>
                                        </div>
                                    </div>
                                    <div className='toggleBox'>
                                        <label className="glass-switch">
                                        <input
                                        type="checkbox"
                                        checked={showDebugLogs}
                                        onChange={(e) => updateToggleDebugLogs(!showDebugLogs)}/>
                                        <i></i>
                                        </label>
                                        <label className="glass-body">Show debug logs</label>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='fusion-warningBox'>
                        {shouldResetCore ? <label className='glass-body warning'>Core file will be overwritten without any merging</label> : null}
                        {localFusionDataChannel === fusionDataChannel.none ? <label className='glass-body warning'>Pushes on no channel will overwrite Core</label> : null}
                        {shouldResetSupportCache ? <label className='glass-body warning'>Channel cache will be overwritten without any merging</label> : null}
                    </div>
                    <div className='timerControls'>
                        <button className='fileSelectButton' onClick={() => uploadFileImmediately()}>Upload Once <FaFileArrowUp className="glass-button-icon-right"/></button>
                        <button className='fileSelectButton' onClick={() => setIsFusionEnabled(!isFusionEnabled)}>
                        {isFusionEnabled ? <div>Pause <FaPauseCircle className="glass-button-icon-right"/></div>
                        : <div>Start Sync<FaPlayCircle className="glass-button-icon-right"/></div>}
                        </button>
                    </div>
                </div>
                : null}
            </div>
        </div>
    );
}

export default FusionControls;