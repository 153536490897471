export enum dataImportType {
        VgTeamListCsv,
}      

export namespace dataImportType {
        export function name(state: dataImportType): string {
                switch (state) {
                        case dataImportType.VgTeamListCsv:
                                return "Video Game Team Lists from CSV template";
                }
        }
}