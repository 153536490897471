import { LogbookMessageLevel } from "../../Models/Common/LogbookMessageLevel";
import { LogbookMessageType } from "../../Models/Common/LogbookMessageType";
import { logbookMessage } from "../../Models/Outbound/Fusion/ApiLogbookMessage";

// Presents a bundle of logbook messages that came from a single API response
export class LogbookBundleViewModel {
    title: string;
    messages?: LogbookMessageViewModel[];
    receivedTimestamp: string;
}

export class LogbookMessageViewModel {
    id: string;
    type: LogbookMessageType;
    level: LogbookMessageLevel;
    timestamp: string;
    message: string;
    details: string | null;

    compactMessage(instance: logbookMessage): string {
        return `${instance.timestamp}: ${instance.message}`;
    }
}