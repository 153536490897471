import React, { useEffect, useState } from 'react';
import "./UploadTracker.scss"
import { RequestResult, RequestResultType } from '../../Models/Common/RequestResult';
import { motion } from 'framer-motion';
import { FaXmark, FaCheck, FaExclamation, FaArrowUp} from "react-icons/fa6";
import { FaRegFileAlt, FaRegListAlt, FaChevronDown } from "react-icons/fa";
import { GrSync } from "react-icons/gr";
import { logbookMessage } from '../../Models/Outbound/Fusion/ApiLogbookMessage';
import { LogbookBundleViewModel } from './LogbookViewModel';
import { LogbookMessageLevel } from '../../Models/Common/LogbookMessageLevel';

interface UploadTrackerProps {
    isUploading: boolean;
    uploadResult: RequestResult<any> | null;
    defaultIcon: UploadTrackerIcon;
    defaultTitle: string;
    defaultSubtitle: string;
    uploadMessage: string;
    logbookBundles?: LogbookBundleViewModel[];
    showDebugLogs?: boolean
}

export enum UploadTrackerIcon {
    Document,
    Report,
    StreamData
}

const UploadTracker: React.FC<UploadTrackerProps> = ({
    isUploading,
    uploadResult,
    defaultIcon,
    defaultTitle,
    defaultSubtitle,
    uploadMessage,
    logbookBundles,
    showDebugLogs
}) => {
    const [uploadTimerMessage, setUploadTimerMessage] = useState<string | null>(null);
    const [result, setResult] = useState<RequestResult<any> | null>(null);
    const [logbooks, setLogbooks] = useState<LogbookBundleViewModel[] | null>(null);
    const [isLogMessagesExpanded, setIsLogMessagesExpanded] = useState<boolean>(false);

    useEffect(() => {
        setResult(uploadResult);
        console.log("Rendering: " + uploadResult?.summaryTitle)
        if (isUploading) {
            const startTime = Date.now();
            const timer = setInterval(() => {
                const elapsedTime = Date.now() - startTime;
                setUploadTimerMessage("Started " + (elapsedTime / 1000).toFixed(0) + " seconds ago");
            }, 1000);
            return () => {
                    clearInterval(timer);
                    setUploadTimerMessage("Started 0 seconds ago");
                }
        }
    }, [isUploading, uploadResult]);

    useEffect(() => {
        if (logbookBundles != null) {
            setLogbooks(logbookBundles);
        }
    }, [logbookBundles]);

    const getDefaultIcon = () => {
        switch (defaultIcon) {
            case UploadTrackerIcon.Document:
                return <FaRegFileAlt className='resultIcon'/>;
            case UploadTrackerIcon.Report:
                return <FaRegListAlt className='resultIcon'/>;
            case UploadTrackerIcon.StreamData:
                return <GrSync className='resultIcon'/>;
        }
    }


    const logHistory = () => {
        if (logbooks?.length === 0) {
            return <div className="logHistoryEmpty">No upload history.</div>;
        }
        return (
            <div className="logHistory">
                {logbooks?.slice().reverse().map((logbook, logbookIndex) => (
                    <div key={logbookIndex} className="logHistorySection">
                        <label className="logHistorySectionTitle">{logbook.receivedTimestamp}: {logbook.title}</label>
                        {logbook.messages?.slice().reverse().map((log, messageIndex) => (
                            (showDebugLogs || log.level !== LogbookMessageLevel.Debug) && (
                                <label key={messageIndex} className={`logHistoryMessage ${LogbookMessageLevel.simpleName(log.level)}`}>{log.message}</label>
                            )
                        ))}
                    </div>  
                ))}
            </div>
        );
    }

    const renderView = () => {
            if (isUploading) {
                return (
                    <div className={`uploadTracker`}>
                        <div className="resultIndicatorUploading"><FaArrowUp className="uploadingIcon"/></div>
                        <div className="uploadTrackerData">
                            <label className="resultTitle">{uploadMessage}</label>
                            <label className="resultSubtitle">{uploadTimerMessage}</label>
                        </div>
                        {logbookBundles != null && (
                            <div className='historyChevronContainer' onClick={() => setIsLogMessagesExpanded(!isLogMessagesExpanded)}>
                                <FaChevronDown className={`historyChevron ${isLogMessagesExpanded ? 'rotate-180' : ''}`}/>
                            </div>
                        )}
                    </div>
                );
            } else if (result == null) {
                return (
                    <div className={`uploadTracker`}>
                        <div className="resultIndicatorDefault">{getDefaultIcon()}</div>
                        <div className="uploadTrackerData">
                            <label className="resultTitle">{defaultTitle}</label>
                            <label className="resultSubtitle">{defaultSubtitle}</label>
                        </div>
                        {logbookBundles != null && (
                            <div className='historyChevronContainer' onClick={() => setIsLogMessagesExpanded(!isLogMessagesExpanded)}>
                                <FaChevronDown className={`historyChevron ${isLogMessagesExpanded ? 'rotate-180' : ''}`}/>
                            </div>
                        )}
                    </div>
                );
            } else if (result.resultType == RequestResultType.ClientError) {
                return (
                    <div className={`uploadTracker`}>
                        <div className="resultIndicatorWarning"><FaExclamation className="resultIcon"/></div>
                        <div className="uploadTrackerData">
                            <label className="resultTitle">{result.summaryTitle}</label>
                            <label className="resultSubtitle">{result.timestamp}</label>
                        </div>
                        {logbookBundles != null && (
                            <div className='historyChevronContainer' onClick={() => setIsLogMessagesExpanded(!isLogMessagesExpanded)}>
                                <FaChevronDown className={`historyChevron ${isLogMessagesExpanded ? 'rotate-180' : ''}`}/>
                            </div>
                        )}
                    </div>
                );
            } else if (result.resultType == RequestResultType.Success) {
                return (
                    <div className={`uploadTracker`}>
                        <div className="resultIndicatorSuccess"><FaCheck className="resultIcon"/></div>
                        <div className="uploadTrackerData">
                            <label className="resultTitle">{result.summaryTitle}</label>
                            <label className="resultSubtitle">{result.timestamp}</label>
                        </div>
                        {logbookBundles != null && (
                            <div className='historyChevronContainer' onClick={() => setIsLogMessagesExpanded(!isLogMessagesExpanded)}>
                                <FaChevronDown className={`historyChevron ${isLogMessagesExpanded ? 'rotate-180' : ''}`}/>
                            </div>
                        )}
                    </div>
                );
            } else if (result.resultType == RequestResultType.ConnectionError) {
                return (
                    <div className={`uploadTracker`}>
                        <div className="resultIndicatorWarning"><FaExclamation className="resultIcon"/></div>
                        <div className="uploadTrackerData">
                            <label className="resultTitle">{result.summaryTitle}</label>
                            <label className="resultSubtitle">{result.timestamp}</label>
                        </div>
                        {logbookBundles != null && (
                            <div className='historyChevronContainer' onClick={() => setIsLogMessagesExpanded(!isLogMessagesExpanded)}>
                                <FaChevronDown className={`historyChevron ${isLogMessagesExpanded ? 'rotate-180' : ''}`}/>
                            </div>
                        )}
                    </div>
                );
            } else {
                return (
                    <div className={`uploadTracker`}>
                        <div className="resultIndicatorError"><FaXmark className="resultIcon"/></div>
                        <div className="uploadTrackerData">
                            <label className="resultTitle">{result.summaryTitle}</label>
                            <label className="resultSubtitle">{result.timestamp}</label>
                        </div>
                        {logbookBundles != null && (
                            <div className='historyChevronContainer' onClick={() => setIsLogMessagesExpanded(!isLogMessagesExpanded)}>
                                <FaChevronDown className={`historyChevron ${isLogMessagesExpanded ? 'rotate-180' : ''}`}/>
                            </div>
                        )}
                    </div>
                );
            }
        }

    return (
        <motion.div className="uploadTrackerContainer">
            {renderView()}
            {isLogMessagesExpanded && logHistory()}
        </motion.div>
    )

};

export default UploadTracker;