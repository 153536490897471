import { BehaviorSubject, combineLatest } from "rxjs";
import { ApiConnectService } from "../../Services/ApiConnectService";
import { TournamentDataService } from "../../Services/TournamentDataService";
import { NavbarData } from "../../components/navbar/Navbar.ViewModel";
import { eventType } from "../../Models/Common/EventType";
import { eventSeason } from "../../Models/Common/EventSeason";
import { gameType } from "../../Models/Common/GameType";
import { RequestResult } from "../../Models/Common/RequestResult";
import { createEventTransfer } from "../../Models/Inbound/CreateEventTransfer";
import { competitorDivision } from "../../Models/Common/CompetitorDivision";
import { eventViewModel } from "../EventsDashboard/EventsDashboard.ViewModel";
import { loadingState } from "../../Models/Common/LoadingState";

export enum CreateEventValidationError {
  eventIdError,
  eventLocationError,
  eventTypeError,
  eventSeasonError,
  gameTypeError,
  creationError,
  otherError,
  initialState,
}

export class CreateEventViewModel {
    navbarData: BehaviorSubject<NavbarData>;
    isEditMode: boolean;
    editModeEventId: string;
    uploadResult = new BehaviorSubject<RequestResult<any> | null>(null);
    isUploading = new BehaviorSubject<boolean>(false);
    validationError = new BehaviorSubject<CreateEventValidationError | null>(CreateEventValidationError.initialState);
    eventDataSubject = new BehaviorSubject<eventViewModel | null>(null);
    loadingStateSubject = new BehaviorSubject<loadingState>(loadingState.loading);

    acs = new ApiConnectService();
    tournamentService = new TournamentDataService(this.acs);

    constructor(
      navbarDataSubject: BehaviorSubject<NavbarData>,
      isEditMode: boolean,
      editModeEventId: string
    ) {
        this.navbarData = navbarDataSubject;
        this.isEditMode = isEditMode;
        this.editModeEventId = editModeEventId;
        if (isEditMode) {
          this.refreshEditEventData();
          this.loadingStateSubject.next(loadingState.loading);
        } else {
          this.loadingStateSubject.next(loadingState.hidden);
        }
    }

    validateEventDetails = (
      eventId: string,
      location: string,
      eventType: eventType,
      eventSeason: eventSeason,
      gameType: gameType) =>
    {
      if (!this.isEditMode && (!eventId || !this.validateEventId(eventId))) { this.validationError.next(CreateEventValidationError.eventIdError); return; };
      if (!location || !this.validateEventLocation(location)) { this.validationError.next(CreateEventValidationError.eventLocationError); return; };
      if (eventType == null) { this.validationError.next(CreateEventValidationError.eventTypeError); return; };
      if (eventSeason == null) { this.validationError.next(CreateEventValidationError.eventSeasonError); return; };
      if (gameType == null) { this.validationError.next(CreateEventValidationError.gameTypeError); return; };
      this.validationError.next(null);
    }

    refreshEditEventData = () => {
      this.tournamentService.GetEventById(this.editModeEventId).subscribe(apiData => {
      if (apiData.result) {
          var eventData = eventViewModel.fromApiEvent(apiData.result);
          this.eventDataSubject.next(eventData ?? null);
          console.log("Scorekeeper dashboard updated")
          setTimeout(() => {
            this.loadingStateSubject.next(loadingState.success);
          }, 200);
          setTimeout(() => {
              this.loadingStateSubject.next(loadingState.hidden);
          }, 1800);
        } else {
          this.loadingStateSubject.next(loadingState.error);
        }
      });
    }

    requestCreateEvent = (
      eventId: string,
      location: string,
      eventType: eventType,
      eventSeason: eventSeason,
      gameType: gameType,
      shouldCalculateStandingsOnUpload: boolean,
      isPrizingEnabled: boolean,
      prizePointsBaseJr: number,
      prizePointsPerWinJr: number,
      prizePointsPerTieJr: number,
      prizePointsPerLossJr: number,
      prizePointsBaseSr: number,
      prizePointsPerWinSr: number,
      prizePointsPerTieSr: number,
      prizePointsPerLossSr: number,
      prizePointsBaseMa: number,
      prizePointsPerWinMa: number,
      prizePointsPerTieMa: number,
      prizePointsPerLossMa: number) => {

      var createEventTransfer: createEventTransfer = {
        eventDetails: {
          location: location,
          type: eventType,
          season: eventSeason,
          gameType: gameType
        },
        eventConfig: {
          shouldCalculateStandingsOnUpload: shouldCalculateStandingsOnUpload,
          isPrizeoutEnabled: isPrizingEnabled
        },
        prizeoutDefinitions: [{
            division: competitorDivision.Junior,
            prizePointsBase: prizePointsBaseJr,
            prizePointsPerWin: prizePointsPerWinJr,
            prizePointsPerTie: prizePointsPerTieJr,
            prizePointsPerLoss: prizePointsPerLossJr
          },
          {
            division: competitorDivision.Senior,
            prizePointsBase: prizePointsBaseSr,
            prizePointsPerWin: prizePointsPerWinSr,
            prizePointsPerTie: prizePointsPerTieSr,
            prizePointsPerLoss: prizePointsPerLossSr
          },{
            division: competitorDivision.Master,
            prizePointsBase: prizePointsBaseMa,
            prizePointsPerWin: prizePointsPerWinMa,
            prizePointsPerTie: prizePointsPerTieMa,
            prizePointsPerLoss: prizePointsPerLossMa
          }]
      };
      
      if (this.isEditMode) {
        console.log("Requesting edit of event with ID: " + eventId);
        this.isUploading.next(true);
        this.tournamentService.EditEvent(eventId, createEventTransfer).subscribe(result => {
          if (!(result && result.result?.eventId == eventId)) {
            this.validationError.next(CreateEventValidationError.creationError);
          }
          this.uploadResult.next(null);
          this.uploadResult.next(result);
          this.isUploading.next(false);
        });
      } else {
        console.log("Requesting creation of event with ID: " + eventId);
        this.isUploading.next(true);
        this.tournamentService.CreateEvent(eventId, createEventTransfer).subscribe(result => {
          if (!(result && result.result?.eventId == eventId)) {
            this.validationError.next(CreateEventValidationError.creationError);
          }
          this.uploadResult.next(null);
          this.uploadResult.next(result);
          this.isUploading.next(false);
        });
      }
    }

    private validateEventId (eventId: string): boolean {
      const regex = /^[a-zA-Z0-9]{1,15}$/;
      return regex.test(eventId);
    }

    private validateEventLocation (location: string): boolean {
      const regex = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
      return regex.test(location);
    }
}