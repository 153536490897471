import { useEffect, useState } from "react";
import "./ActionField.scss"
import React from "react";
import '../glassTheme/Common.scss';
import { FaUser } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import { FaUserPlus } from "react-icons/fa6";


interface ActionFieldProps {
    text: string;
    onTextChange: (text: string) => void;
    iconType: ActionFieldType;
}

export enum ActionFieldType {
    Admin,
    None
}

const ActionField: React.FC<ActionFieldProps> = ({text, onTextChange}) => {
    const [textState, setTextState] = useState<string>(text);

    
    useEffect(() => {
        setTextState(text);
    }, [text]);
    
    const updateText = (text: string) => {
        onTextChange(text);
        setTextState(text);
    }

    const isValid = text.length > 0;

    return(
        <div className={`dark-glass action-field-box`}>
        <a className="action-field-btn" href="#">
            {isValid ? (
                <FaUser className={`action-field-icon active-icon`}/>
            ) : (
                <FaUserPlus className={`action-field-icon`} />
            )}
        </a>
        <input
            type="text"
            className="action-field-input"
            placeholder="Enter your name..."
            value={text}
            onChange={(e) => updateText(e.target.value)}/>
        </div>
    )
}


export default ActionField;